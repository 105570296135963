<template>
	<div>
		<el-dialog v-model="pop" title="" width="520px" destroy-on-close :align-center="true" @close="DialogClose">
			<template #header>
				<div class="flex-row-center-center font-blod co-1F2D48 fz-18 pl-20 ptb-20">{{detail.name}}</div>
			</template>
			<div class="fz-15 co-1F2D48 pb-30 plr-20">
				<el-upload ref="ElUploadFile" drag :action="`${config.request_host}${detail.upapi}`"
					:on-success="UpLoadSuccess" :headers="{Authorization: token, version: 'v1'}" :data="detail.data">
					<div class="flex-column-center-center">
						<img class="wh-34" src="@/assets/upload_icon.png" alt="">
						<div class="fz-14 co-0066FF mt-10">{{StartUpLoad ? `上传中...` : '点击或将文件拖拽到这里上传'}}</div>
					</div>
				</el-upload>
			</div>
			<template #footer>
				<div class="flex-row-center-center pr-20">
					<el-button class="down-btn" plain type="primary" @click="DownLoadFile" v-if="detail.downapi">模板下载</el-button>
					<el-button class="sure-btn" type="primary" @click="Submit">确认</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script lang="ts" setup>
	import { ref, defineExpose, defineEmits, getCurrentInstance, onUnmounted } from 'vue';
	import config from '@/api/config';

	const { proxy } = getCurrentInstance() as any;
	const emits = defineEmits(["change"]);
	const ElUploadFile = ref<any>();
	const pop = ref(false);
	const detail = ref<any>();
	const loading = ref(false);
	const file = ref("");
	const token = ref<any>(localStorage.getItem('token'));
	const FileId = ref("");
	let timer : any = "";
	const percentage = ref(0);
	const StartUpLoad = ref(false);

	onUnmounted(() => {
		timer && clearInterval(timer);
	});

	const Submit = () => {
		close();
	};
	const UpLoadSuccess = (data : any) => {
		if (data.code == 200) {
			FileId.value = data.data;
			StartUpLoad.value = true;
			QuerypProgress();
		} else {
			proxy.$message.error("文件上传失败");
		}
	};
	const DownLoadFile = () => {
		if (!detail.value.downapi) {
			proxy.$message.error("api链接不能为空");
			return false;
		}
		if (loading.value) {
			return false;
		}
		loading.value = true;
		proxy.$api.tokenRequest({
			url: detail.value.downapi,
			isLoading: '加载中...',
			data: detail.value.data,
			success: (res : any) => {
				loading.value = false;
				let a : any = document.createElement('a');
				a.href = res.data;
				a.setAttribute('download', `${detail.value.downname}模板`);
				a.click();
				a = null;
			},
			fail: () => {
				loading.value = false;
			},
		});
	};
	const QuerypProgress = () => {
		timer && clearTimeout(timer);
		proxy.$api.tokenRequest({
			url: '/queue/progress',
			data: {
				id: FileId.value
			},
			success: (res : any) => {
				if (res.data.status == 1) {
					StartUpLoad.value = true;
					timer = setTimeout(() => {
						clearTimeout(timer);
						QuerypProgress();
					}, 500);
				} else if (res.data.status == 2) {
					StartUpLoad.value = true;
					percentage.value = res.data.progress;
					timer = setTimeout(() => {
						clearTimeout(timer);
						QuerypProgress();
					}, 500);
				} else if (res.data.status == 3) {
					timer && clearTimeout(timer);
					proxy.$message.success("文件上传完成");
					StartUpLoad.value = false;
					percentage.value = 0;
					ElUploadFile.value.clearFiles();
					close();
					emits("change");
				} else if (res.data.status == 4) {
					StartUpLoad.value = false;
					percentage.value = 0;
					if (res.data.message) {
						proxy.$message.error(res.data.message);
					} else {
						if (res.data.history) {
							proxy.$message.error(res.data.history[res.data.history.length - 1].message);
						} else {
							proxy.$message.error("文件上传失败，请稍后重试");
						}
					}
				}
			},
		});
	};
	const DialogClose = () => {
		timer && clearInterval(timer);
	};
	const open = (params : any) => {
		pop.value = true;
		detail.value = params;
	};
	const close = () => {
		pop.value = false;
	};

	defineExpose({
		open,
		close,
	});
</script>

<style lang="scss" scoped>
	@import "@/styles/publicscss/publicscss.scss";

	.down-btn {
		width: 110px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		line-height: 36px;
	}

	.sure-btn {
		width: 110px;
		height: 36px;
		border-radius: 4px;
		font-size: 14px;
		color: #FFFFFF;
		line-height: 36px;
	}
</style>