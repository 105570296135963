// 接口域名和状态码的全局配置文件
export default {
	// 接口配置
	method: "GET", // 默认请求方式
	// request_host: 'https://coriton-api.togytech.net', // 接口域名
	request_host: 'https://wms-api.coriton.cn', // 接口域名
	login_api: '/admin/login', // 登录接口
	refresh_token_api: '/refreshToken', // 刷新token的API
	uploadFile_host: 'https://wms-api.coriton.cn', // 上传文件接口域名
	uploadFile_api: '/admin/upload', // 上传文件API
	uploadFile_loading_txt: '上传图片中', // 上传图片默认loading显示的文字
	request_no_token_code: 4000, // 请求没有携带token的code值
	request_token_error: 4001, // 被踢下线
	request_token_overdue_code: 4002, // token过期的code值
	request_bing_phone: 2001, // 需要绑定手机号,小程序中会返回session_key
	request_token_unable_renew: 6001, // token无法续期；
	request_password_changed: 6002, // 密码已修改，请重新登录；
	request_user_deleted: 6003, // 用户已被删除/已注销；
	
	// 表格/分页区域配置
	PagesSize: [50, 100, 150], // 分页每页显示条数
	HeaderCellStyle: {
		"color": "#1F2D48",
		"font-size": "15px",
		"font-weight": "blod",
		"background": "#F6F6F8",
		"text-align": "center",
	}, // 表格表头样式
	CellStyle: {
		"color": "#1F2D48",
		"font-size": "14px",
		"padding": "16px 0",
		"text-align": "center",
	}, // 表格每一条样式
	
	// 商品
	WeightUnit: [{
		value: 1,
		label: "T(吨)",
	}, {
		value: 2,
		label: "kg(千克)",
	}, {
		value: 3,
		label: "g(克)",
	}], // 重量单位配置参数
	QualityList: [{
		value: 1,
		label: "先进先出",
	}], // 策略配置参数
	QualityUnit: [{
		value: 1,
		label: "天",
	}, {
		value: 2,
		label: "月",
	}], // 保质期单位配置参数
	
	// 入库订单
	InOrderFrom: [{
		value: 1,
		label: "自建",
	}], // 入库订单来源
	UserType: [{
		value: 1,
		label: "客户",
	}, {
		value: 2,
		label: "供应商",
	}], // 入库订单用户类型
	InOrderType: [{
		value: -1,
		label: "全部",
	}, {
		value: 1,
		label: "采购入库",
	}, {
		value: 2,
		label: "销售退货",
	}, {
		value: 3,
		label: "调拨入库",
	}, {
		value: 4,
		label: "其他入库",
	}, {
		value: 5,
		label: "生产退料",
	}, {
		value: 6,
		label: "产品入库",
	}],
	InWarehouseStatus: [{
		value: 0,
		label: "全部"
	}, {
		value: 10,
		label: "待审核"
	}, {
		value: 20,
		label: "待收货"
	}, {
		value: 30,
		label: "待验货"
	}, {
		value: 40,
		label: "待上架"
	}, {
		value: 50,
		label: "待入库"
	}, {
		value: 60,
		label: "待出库"
	}, {
		value: 70,
		label: "已完成"
	}, {
		value: -10,
		label: "已拒绝"
	}, {
		value: -20,
		label: "已作废"
	}], // 入库订单状态筛选
	
	// 出库订单
	OutWarehouseStatus: [{
		value: 0,
		label: "全部"
	}, {
		value: 10,
		label: "待审核"
	}, {
		value: 50,
		label: "待拣货"
	}, {
		value: 60,
		label: "待复核"
	}, {
		value: 80,
		label: "待出库"
	}, {
		value: 90,
		label: "已完成"
	}, {
		value: -10,
		label: "已拒绝"
	}, {
		value: -20,
		label: "已作废"
	}], // 出库订单状态筛选
	OutOrderType: [{
		value: -1,
		label: "全部",
	}, {
		value: 1,
		label: "采购退货",
	}, {
		value: 2,
		label: "销售出库",
	}, {
		value: 3,
		label: "调拨出库",
	}, {
		value: 4,
		label: "其他出库",
	}, {
		value: 5,
		label: "生产领料",
	}, {
		value: 6,
		label: "产品退库",
	}],
	
	RelocationStatus: [{
		value: -1,
		label: "全部"
	}, {
		value: 10,
		label: "待审核"
	}, {
		value: 20,
		label: "待移库"
	}, {
		value: 30,
		label: "已完成"
	}], // 移库状态管理
	
	// 报表管理
	LedgerType: [{
		value: 1,
		label: "入库",
	}, {
		value: 2,
		label: "出库",
	}, {
		value: 3,
		label: "移库",
	}], // 库存台账类型
	InventoryStatus: [{
		value: 0,
		label: "全部"
	}, {
		value: 10,
		label: "待盘点"
	}, {
		value: 20,
		label: "待审核"
	}, {
		value: 30,
		label: "已完成"
	}], // 盘点状态
	InventoryType: [{
		value: 1,
		label: "暗盘"
	}, {
		value: 2,
		label: "明盘"
	}], // 盘点管理类型
	ReportingLossesStatus: [{
		value: 0,
		label: "全部"
	}, {
		value: 10,
		label: "待审核"
	}, {
		value: 20,
		label: "待赔偿"
	}, {
		value: 30,
		label: "已结束"
	}], // 报损状态
	LossReportingType: [{
		value: 0,
		label: "正常"
	}, {
		value: 1,
		label: "损坏"
	}, {
		value: 2,
		label: "过期"
	}, {
		value: 3,
		label: "丢失"
	}, {
		value: 4,
		label: "招待"
	}], // 报损类型
	
	// 托盘
	TrayShapList: [{
		value: 1,
		label: "日字型"
	}, {
		value: 2,
		label: "川字型"
	}],
	TrayMaterialList: [{
		value: 1,
		label: "木质"
	}, {
		value: 2,
		label: "塑料"
	}],
	
	// 权限类型
	AuthTypeList: [{
		value: 1,
		label: "仓库管理员",
	}, {
		value: 2,
		label: "PDA",
	}, {
		value: 3,
		label: "其他员工",
	}],
	
	// 公共
	StatusList: [{
		value: 1,
		label: "启用",
	}, {
		value: 0,
		label: "禁用",
	}], // 禁用启用配置参数
	YesOrNo: [{
		value: 1,
		label: "是"
	}, {
		value: 0,
		label: "否"
	}],
	ReviewStatus: [{
		value: -1,
		label: "全部"
	}, {
		value: 10,
		label: "待审核"
	}, {
		value: 20,
		label: "已通过"
	}],
	
	// 随意自定义字段-假页面使用也可不使用
	Params: {
		params1: "",
		params2: "",
		params3: "",
		params4: "",
		params5: "",
		params6: "",
		params7: "",
		params8: "",
		params9: "",
		params10: "",
		params11: "",
		params12: "",
		params13: "",
		params14: "",
		params15: "",
		params16: "",
		params17: "",
		params18: "",
		params19: "",
		params20: "",
		params21: "",
		params22: "",
		params23: "",
		params24: "",
		params25: "",
		params26: "",
		params27: "",
		params28: "",
		params29: "",
		params30: "",
		params31: "",
		params32: "",
		params33: "",
		params34: "",
		params35: "",
		params36: "",
		params37: "",
		params38: "",
		params39: "",
		params40: "",
	},
	
	// websocket
	websocket: {
		url: 'wss://wms-api.coriton.cn:9998',
		// url: 'wss://coriton-api.togytech.net:9887',
		heartbeat: 15, // 心跳时间，秒
		maxReconnectionNum: 15, // 失败或错误后，最大重连次数
		action: {
			error: 'error',
			login: "login", // 登录
			ping: "ping", // 心跳包
		}, // action数据类型
	}
};